//use blog (famaliving) Blog authors Famaliving 2023
'use strict'
import axios from 'axios'
import $ from 'jquery'
import { FamaUtils } from '../../classes/FamaUtils'

export class BlogAuthor {

    utils = window.fama.utils || FamaUtils.getInstance()
    /** Selectors **/
    $entryContainer = $('#js-blog-author .js-blog-entry-container')
    $currentPage = $('#js-blog-author .js-page-value')
    $currentPageID = $('#js-blog-author .js-pageId-value')
    $currentAuthorID = $('#js-blog-author .js-authorId-value')
    $currentLocale = $('#js-blog-author .js-locale-value')
    $commentsLoader = $('#js-blog-author .comment-loader')
    $totalPages = $('#js-blog-author').attr('data-lastPage')

    currentOffset = 1;
    fin= false;

    /** Api Endpoints **/
    loadMorePosts = '/api/get-more-entries-author'

    constructor() {
        //console.log('blog author');
        this.init();
    }

    init() {
        this.eventListeners();
    }

    eventListeners () {
        $(document).on('scroll', (e) => {this.onScroll()});
    }

    onScroll() {

        let contenido=
            Math. round($('#js-blog-author .two-columns-wrap').outerHeight()
                + Math.round($('#js-blog-author .two-columns-wrap').offset().top));

        if (window.scrollY + window.innerHeight >= contenido && !this.fin) {
            this.fin=true;

            let data = {
                author_id: this.$currentAuthorID[0].value,
                pageId: this.$currentPageID[0].value,
                page: this.$currentPage[0].value,
                locale: this.$currentLocale[0].value,
            }

            if (this.currentOffset <= parseInt(this.$totalPages)) {
                this.$commentsLoader.show();
                axios
                    .post(this.loadMorePosts, data)
                    .then((response) => {

                        this.currentOffset = response.data.currentPage;
                        let locale = this.$currentLocale[0];
                        response.data.blogItems.forEach((element) => {
                        // TODO: Relacionar el local con la inserccion dinámica

                        let entry = `<div class="blog-entry">
                        <div class="blog-content">
                            <div class="aux-item">
                                <a href="${element.detailUrl}" class="img-link" >
                                    <div class="aux-img">
                                        <div class="wrap-img">
                                            <img src="${element.imagen}" loading="lazy" class="lazyload img" alt="${element.nombre_imagen}" title="${element.nombre_imagen}">
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="block-text text generic">
                                <div class="badge-blog">
                                    <p class="likes">
                                    <span class="likes-count">${element.likes_count}</span>`
                                    if (!document.cookie.includes(`liked_${element.id}_32`)) {
                                        entry += `<a href="#" class="like-button" data-item="${element.id}" data-type="32">
                                                    <i class="fa fa-heart-o heart heart--pink" aria-hidden="true"></i>
                                                </a>`;
                                    }
                                    else {
                                        entry += `<a href="#" class="liked" data-item="${ element.id }" data-type="32">
                                            <i class="fa fa-heart heart heart--pink" aria-hidden="true"></i>
                                        </a>`
                                    }

                                    entry +=`</p>
                                </div>`
                                entry +=`<div class="info">
                                    <div class="date-info">${element.postdate}</div>
                                </div>`
                                var subs = element.descripcion.substring(0, 150);
                                entry +=`<span class="author a" href="${element.autorUrl}">${element.autor}</span>
                                <a href="${element.detailUrl}">
                                    <h3 class="block-title">${element.titulo}</h3>
                                </a>
                                <p class="desc">${subs}...</p>
                                <hr>
                            </div>
                        </div>
                    </div>`
                        ;

                        this.$entryContainer[0].innerHTML += entry;
                        });

                        this.$currentPage[0].value++;
                        this.$commentsLoader.hide();
                        this.fin = false;
                    })
                .catch(function (error) {
                    this.utils.toast.error(error)
                })
            }
        }

    }
}
