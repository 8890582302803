import $ from 'jquery'
import {FamaUtils} from "../../classes/FamaUtils";

export class Contact {
    utils = fama.utils || FamaUtils.getInstance();

    $submitButton = $("#contact_famaliving2023 form button[type='submit']")

    constructor() {
        this.utils.debug('Contact Loaded')
        this.init();
    }

    init() {
        this.eventListeners();

        $("#contact_famaliving2023").each((key, item) => {
            var id = $(item);
            id.find("select[name='shops']").select2();

            this.$submitButton.off().on('click', (e) => {
                e.preventDefault();
                var form_ok = true;

                if (!$("#contact_famaliving2023 input[type='checkbox']").is(":checked")) {
                    $("#contact_famaliving2023 input[type='checkbox']").parent().addClass('error');
                    form_ok = false;
                } else {
                    $("#contact_famaliving2023 input[type='checkbox']").parent().removeClass('error');
                }

                $("#contact_famaliving2023 input[type='text'], #contact_famaliving2023 textarea").each( (i, item) => {
                    if ($(item).val() == "" && ($(item).attr("name") == "nombre" || $(item).attr("name") == "apellidos" ||
                        $(item).attr("name") == "comentario")) {
                        $(item).addClass('error');
                        form_ok = false;
                    } else {
                        $(item).removeClass('error');
                    }
                });

                $("#contact_famaliving2023 input[name='email']").each((i, item) => {
                    if ($(item).val().trim() == "") {
                        $(item).addClass('error');
                        form_ok = false;
                    }
                    else {
                        if(!this.utils.isEmailValid($(item).val()) && !this.utils.isTlfValid($(item).val())){
                            $(item).addClass('error');
                            form_ok = false;
                        }else if(this.utils.isEmailValid($(item).val())){
                            $(item).removeClass('error');
                        }else if(this.utils.isTlfValid($(item).val())){
                            $(item).removeClass('error');
                        }
                    }
                });

                if (form_ok) {
                    this.$submitButton.closest('.wrap-btnDefault').addClass('loading');

                    var $item=$(item);
                    $item.css('disabled', true);

                    var options = {
                        nombre: $("#contact_famaliving2023 input[name='nombre']").val(),
                        email: $("#contact_famaliving2023 input[name='email']").val(),
                        shop: $("#contact_famaliving2023 select[name='shops']").val(),
                        comentario: $("#contact_famaliving2023 textarea[name='comentario']").val(),
                        city: $("#contact_famaliving2023 input[name='city']").val(),
                        lang: $("#contact_famaliving2023 input[name='lang']").val(),
                        emailToSend: $("#contact_famaliving2023 input[name='emailToSend']").val(),
                    };

                    $.ajax({
                        url: "/ajax.php?module=contacto&action=contact&language=" + options.lang,
                        data: options,
                        type: "post",
                        async: true,
                        dataType: "json",
                        beforeSend: () => {
                            this.$submitButton.prop('disabled', true);
                        }
                    })
                    .done((r) => {

                        this.utils.toast.log(r.message);

                        $("#contact_famaliving2023 input[name='nombre']").val("");
                        $("#contact_famaliving2023 input[name='email']").val("");
                        $("#contact_famaliving2023 input[name='city']").val("");

                        $("#contact_famaliving2023 textarea[name='comentario']").val("");
                        this.$submitButton.prop('disabled', false);
                        $("#contact_famaliving2023 input[type='checkbox']").prop('checked', false);

                        $('#contact_famaliving2023 select[name="shops"]').find('option[value="0"]').attr('selected','selected');
                        $('#contact_famaliving2023 select[name="shops"]').prop('selectedIndex',0);

                        $('#contact_famaliving2023 select[name="shops"]').select2('destroy');
                        $('#contact_famaliving2023 select[name="shops"]').find("select option").eq(0).html();
                        $('#contact_famaliving2023 select[name="shops"]').select2();

                        var locale = options.lang.toUpperCase();
                        var eventName = locale + ' - Click - Contacto general';
                        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                            m=s.getElementsByTagName(o)
                                [0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

                        ga('send', 'event', 'Contacto', 'Click', eventName);
                    })
                    .fail(e => {
                        this.utils.toast.error(e.message || e.responseJSON.message || 'Undefined Error');
                    })
                    .always(() => {
                        this.$submitButton.closest('.wrap-btnDefault').removeClass('loading');
                        $item.css('disabled', false);
                    })
                }
            });
        });
    }

    eventListeners() {
        //$('.form-contact-product').on('submit', (e) => {this.contactSend()});
        $(document).on('click', '.select2', (e) => {this.clickSelect2(e)});
        $(document).on('click', '.js-toggleCollapse', (e) => {this.toggleCollapse(e)});
        $(document).on('select2:select', '#list-shopsContact', (e) => {this.changeProvince(e)});
    }

    toggleCollapse(e){
        let $elem = $(e.currentTarget);
        let target = $elem.data('target');
        let $parent = $('.country[data-target="'+target+'"]');

        if (e.target instanceof HTMLAnchorElement)
            return

        if (($elem).data('target') === 'ESPAÑA' )
            this.initSelect2();

        $parent.toggleClass('collapse');
        $parent.siblings('.country:not(".collapse")').addClass('collapse');
    }

    initSelect2() {
        if( $("select[name='list-shops']").length > 0){
            $("select[name='list-shops']").select2();
        }
    }

    clickSelect2(e) {
        e.stopPropagation();
    }

    changeProvince(e) {
        //let $elem = $(e.currentTarget);
        let $elem = $('#list-shopsContact').select2('data');
        let elemId= parseInt($elem[0].id);
        $('.country-content .with-select').removeClass('filter');
        $('.country-content .shop-item[data-countryid='+ 68 +']').removeClass('isFiltered');

        $('.country-content .with-select .shop-item').each(function(){

            if( (parseInt($(this).data('regionid')) !== elemId)){
                $(this).removeClass('isFiltered');
                $(this).addClass('d-none');
            }else{
                $(this).addClass('isFiltered');
                $(this).removeClass('d-none');
            }

            $('.country-content .with-select').addClass('filter');
        });

        if (elemId === 0) {
            $('.country-content .with-select').removeClass('filter');
            $('.country-content .shop-item[data-countryid='+ 68 +']').removeClass('isFiltered');
            $('.country-content .shop-item[data-countryid='+ 68 +']').removeClass('d-none');
        }
    }

    //contactSend() {
        //console.log('WTF DO THIS FUNCTION?')
    //}
}

