//use home(page of geoposition) (famaliving)
import {FamaUtils} from "../../classes/FamaUtils";

export class GeoInit {
    utils = FamaUtils.getInstance();

    constructor() {
        fama.utils.debug('GeoInit loaded')
        this.init();
        this.initForm();
        this.eventListeners();
    }

    init() {
        if(this.utils.checkCookie('geo')) {
            $(".geo-content").hide();
        }else{
            $("body").addClass("stop-scroll");
        }
    }

    eventListeners() {
        $('.js-geoChange').on('click', () => {this.geoChangeForm()});
        $('.js-geoGo').on('click', () => {this.geoGo()});
    }

    initGeo(){
        $("body").addClass("stop-scroll");
        this.utils.setCookie('geo', $('.geo-content').find("select[name='pais'] :selected").data('iso'), 100);
        this.skipGeo(); //skip img and popup
    }

    skipGeo() {
        $(".geo-content").fadeOut();
        $("body").removeClass("stop-scroll");
    }

    geoChangeForm(){
        $('.wrap-init').addClass('d-none');
        $('.wrap-geo-form').removeClass('d-none');
    }

    geoGo(){
        this.initGeo();
    }

    initForm () {
        $(".wrap-geo-form").each((key, item) => {
            var id = $(item);
            var countryIdSelected, countryIsoSelected;

            id.find("select[name='pais']").select2({
                dropdownParent: $('.geo-content')
            });

            id.find("select[name='pais']").on("change", () => {
                countryIdSelected=id.find("select[name='pais']").val();
                countryIsoSelected=id.find("select[name='pais'] :selected").data('iso');
                this.utils.setCookie('geo', countryIsoSelected, 100);
            });

            $(".js-aceptChangeGeo").on("click", () => {
                $(".geo-content").hide();
                $("body").removeClass("stop-scroll");
            });

        });
    }

}
