//MAPs_famaliving2023
//use finde your store (famaliving)

import {FamaUtils} from "../../classes/FamaUtils";
import MarkerClusterer from '@googlemaps/markerclustererplus'; //famaliving in 'search your store'
//import {MarkerClusterer} from '@googlemaps/markerclusterer';
import {forEach, orderBy, map} from 'lodash';

/**
 *
 */
export class MapMenu {
    utils = fama.utils || FamaUtils.getInstance();
    $primeContainer = $('.js-wrapStore');

    _map = null;
    _at = null;
    _kt = null;
    GET_HTML_AUTOCOMPLETE = 0;
    GET_JS_AUTOCOMPLETE = 1;
    DONT_CENTER_ON_INIT = 0;
    NUMBER_OF_RESULTS = 0;
    CURRENT_ZOOM = 0;
    markers = [];
    markerCluster = null;
    storeNear = [];
    isInit = true;
    hayValor = false;
    searchTimeout = null;
    positionIsSet = false;
    latUser = null;
    longUser = null;
    countryUser = 'es';
    fromButton = false;
    isGoogleRes = false;

    config = {
        distance: 245,
        threshold: 1,
        clusterRadius: 245
    };

    mapStyles = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#575757"
                },
                {
                    "lightness": -30
                },
                {
                    "weight": 1
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#333333"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#d9d9d9"
                },
                {
                    "lightness": -35
                },
                {
                    "weight": 1
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "saturation": -5
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "saturation": -5
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "labels.text",
            "stylers": [
                {
                    "color": "#333333"
                },
                {
                    "saturation": -5
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 30
                },
                {
                    "lightness": 40
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "saturation": -5
                },
                {
                    "lightness": 100
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "saturation": -35
                },
                {
                    "lightness": 100
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "saturation": 5
                },
                {
                    "lightness": 5
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "saturation": -5
                },
                {
                    "lightness": -100
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#e9e9e9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ]


    constructor() {
        this.utils.debug('MapMenu loaded')
        this.init();
    }
 
    init() {
        this.eventListeners();
        this.initMap();
        this.initUbication();
        this.checkCookie('geoCity');
    }

    eventListeners() {
        $("input[name='searchCityAll']").on('input', (e) => {
            this.keyUpCityAll(e)
        });
        //input filter all stores
        $('.js-seeListStore').on('click', (e) => {
            this.seeListStore(e)
        }); //see all stores
        $('.js-stepSelectOtherShop').on('click', (e) => {
            this.seeListStore(e)
        });

        $('.js-selectedStore').on('click', (e) => {
            this.openSelectStore(e)
        });//open from menu vertical
        $('.js-openSelectStore').on('click', (e) => {
            this.openSelectStore(e)
        });//open from nav, if you have a store

        $('.js-storeClose').on('click', () => {
            this.closeSelectStore()
        });
        $('.js-back').on('mouseenter', (e) => {
            this.openBack(e)
        });
        $('.js-back').on('mouseleave', (e) => {
            this.closeBack(e)
        });

        $('.js-full-level-black').on('click', () => {
            this.closeAllMenuVertical()
        });
    }

    initStore() {
        let mapWrap = $('.wrap-store-map');
        let latStore = mapWrap.data('lat');
        let longStore = mapWrap.data('long');
        let markerInitLatLng = new google.maps.LatLng(latStore, longStore);

        var markerInitOptions = {
            zoom: 18,
            center: markerInitLatLng,
            disableDefaultUI: true,
            zoomControl: true,
            map: map,
            styles: this.mapStyles,
        }
        var map = new google.maps.Map(document.getElementById("mapStore"), markerInitOptions);

        const iconStore = {
            famaliving: {
                icon: '/images/redMarker.svg',
            },
            clicked_famaliving: {
                icon: '/images/redMarkerChecked.svg',
            }
        };

        if (mapWrap.hasClass('isStoreLatLongHidden')) {
            map.setZoom(5);
        } else {
            var marker = new google.maps.Marker({
            //var marker = new google.maps.marker.AdvancedMarkerElement({
                //map,
                map: map,
                position: {lat: latStore, lng: longStore},
                icon: iconStore.famaliving.icon,

            });
            marker.setIcon(iconStore.famaliving.icon);
            map.setZoom(18);
        }
    }

    initMap() {
        this.countryUser = $('.wrap-store').data('lang').toLowerCase();
        var markers = [];
        var map;
        let markerInitLatLng = new google.maps.LatLng(38.0028590, -1.1281320);

        var markerInitOptions = {
            zoom: 15,
            center: markerInitLatLng,
            disableDefaultUI: true,
            zoomControl: true,
            map: map,
            styles: this.mapStyles,
            //mapId: "mapMenu",
        }

        map = new google.maps.Map(document.getElementById("mapMenu"), markerInitOptions);
        this._map = map;


        this.markers.forEach((store) => {

            const icons = {
                famaliving: {
                    icon: '/images/redMarker.svg',
                },
                fama: {
                    icon: '/images/blueMarker.svg',
                },
                clicked: {
                    icon: '/images/blueMarkerChecked.svg',
                },
                clicked_famaliving: {
                    icon: '/images/redMarkerChecked.svg',
                }

            }

            let data = {
                address: store.address,
                city: store.city,
                country_name: store.country,
                region_name: store.region,
                //country_id: store.country_id,
                //created_at: store.created_at,
                email: store.email,
                facebook: store.facebook,
                fax: store.fax,
                fotos: store.images,
                id: store.id,
                instagram: store.instagram,
                iw4id: store.iw4id,
                language: store.language,
                mondayFriday: store.mondayFriday,
                saturday: store.saturday,
                name: store.name,
                phone: store.phone,
                po_box: store.po_box,
                point_latitude: store.point_latitude,
                point_longitude: store.point_longitude,
                postal_code: store.postal_code,
                region: store.region,
                web: store.slug,
                phone_2: store.phone_2,
                has_whatsapp: store.has_whatsapp,

            };

            //put markers on the map
            if (store.name.toUpperCase().indexOf('FAMALIVING') > -1) {
                var marker = new google.maps.Marker({
                //var marker = new google.maps.marker.AdvancedMarkerElement({
                    position: {lat: store.point_latitude, lng: store.point_longitude},
                    //map,
                    map: map,
                    scale: 5,
                    icon: icons.famaliving.icon,
                    data: data,
                    isFamaliving: true,
                });
                markers.push(marker);
            } else {
                var marker = new google.maps.Marker({
                //var marker = new google.maps.marker.AdvancedMarkerElement({
                    position: {lat: store.point_latitude, lng: store.point_longitude},
                    //map,
                    map: map,
                    scale: 5,
                    icon: icons.fama.icon,
                    data: data,
                    isFamaliving: false,
                });
                markers.push(marker);
            }

            //click on marker in the map

            google.maps.event.addListener(marker, 'click', () => {
                var coordenadas = new google.maps.LatLng(marker.data.point_latitude, marker.data.point_longitude);

                this._map.setCenter(coordenadas);
                this._map.setZoom(15);

                if (marker.isFamaliving) {
                    marker.setIcon(icons.clicked_famaliving.icon);
                } else {
                    marker.setIcon(icons.clicked.icon);
                }

                var po_box = marker.data.po_box ? marker.data.po_box : "";

                var storeContent = `
                    <div>`
                if (marker.data.address && marker.data.address != '' && marker.data.address != null) {
                    storeContent = storeContent + `<span>${marker.data.address}, </span>`
                }
                if (marker.data.po_box && marker.data.po_box != '' && marker.data.po_box != null) {
                    storeContent = storeContent + `<span>${marker.data.po_box}, </span>`
                }
                if (marker.data.postal_code && marker.data.postal_code != '' && marker.data.postal_code != null) {
                    storeContent = storeContent + `<span>${marker.data.postal_code}, </span>`
                }
                if (marker.data.city && marker.data.city != '' && marker.data.city != null) {
                    storeContent = storeContent + `<span>${marker.data.city}</span>`
                }
                `
                    </div>
                    `

                if ((marker.data.region && marker.data.region != '' && marker.data.region != null) && (marker.data.country_name && marker.data.country_name != '' && marker.data.country_name != null)) {
                    storeContent = storeContent + `<div>${marker.data.region}<span>(${marker.data.country_name})</span></div>`
                }
                if ((marker.data.region && marker.data.region != '' && marker.data.region != null) && (!marker.data.country_name || marker.data.country_name == '' || marker.data.country_name == null)) {
                    storeContent = storeContent + `<div>${marker.data.region}</div>`
                }
                if ((!marker.data.region || marker.data.region == '' || marker.data.region == null) && (marker.data.country_name && marker.data.country_name != '' && marker.data.country_name != null)) {
                    storeContent = storeContent + `<div>${marker.data.country_name}</div>`
                }

                $('.js-wrapStore .store-info').html(storeContent);

                if (marker.data.name != null && marker.data.name != '') {
                    let name;
                    name = (marker.data.name).trim();


                    if (name.trim().includes('Famaliving')) {
                        let storeNearArr = [];
                        storeNearArr = name.split(' ');

                        var storeNearArrLast = storeNearArr.splice(1, storeNearArr.length - 1);
                        var strName = storeNearArrLast.join(' ');

                        $('.js-wrapStore .store-name .name').html(strName);
                    } else {
                        $('.js-wrapStore .store-name .name').html(name);
                    }
                } else {
                    $('.js-wrapStore .store-name .name').html('');
                }

                if (marker.data.phone != '' && marker.data.phone != null) {
                    $('.js-wrapStore .store-buttons .tlf').removeClass('d-none');
                    $('.js-wrapStore .store-buttons .tlf').attr('href', 'tel:' + `${marker.data.phone}`);
                } else if (marker.data.phone_2 != '' && marker.data.phone_2 != null) {
                    $('.js-wrapStore .store-buttons .tlf').removeClass('d-none');
                    $('.js-wrapStore .store-buttons .tlf').attr('href', 'tel:' + `${marker.data.phone_2}`);
                } else {
                    $('.js-wrapStore .store-buttons .tlf').addClass('d-none');
                    $('.js-wrapStore .store-buttons .tlf').attr('href', '');
                }

                if (marker.data.phone_2 != '' && marker.data.phone_2 != null && marker.data.has_whatsapp) {
                    $('.js-wrapStore .store-buttons .wapp').removeClass('d-none');
                    $('.js-wrapStore .store-buttons .wapp').attr('href', 'https://api.whatsapp.com/send?phone=' + `${marker.data.phone_2}` + '&text=Hola');
                } else {
                    $('.js-wrapStore .store-buttons .wapp').addClass('d-none');
                    $('.js-wrapStore .store-buttons .wapp').attr('href', '');
                }

                if (marker.data.email != '' && marker.data.email != null) {
                    $('.js-wrapStore .store-buttons .email').removeClass('d-none');
                    $('.js-wrapStore .store-buttons .email').attr('href', 'mailto:' + `${marker.data.email}`);
                } else {
                    $('.js-wrapStore .store-buttons .email').addClass('d-none');
                    $('.js-wrapStore .store-buttons .email').attr('href', '');
                }

                let webStore;
                if (marker.data.web && marker.data.web != '' && marker.data.web != null) {
                    webStore = marker.data.web;
                    if (webStore.toLowerCase().indexOf('http') === -1) {
                        webStore = "https://" + webStore;
                    }
                    $('.js-wrapStore .wrap-buttons .web').attr('href', `${webStore}`);
                }

                if (marker.data.saturday != '' && marker.data.saturday != null || marker.data.mondayFriday != '' && marker.data.mondayFriday != null) {
                    let titleScheudule = $('.js-wrapStore .map-container-info').data('schedule');
                    $('.js-wrapStore .store-schedule .title').html(`${titleScheudule}`);
                } else {
                    $('.js-wrapStore .store-schedule .title').html('');
                }

                if (marker.data.saturday != '' && marker.data.saturday != null) {
                    $('.js-wrapStore .store-schedule-content  .saturday-title').removeClass('d-none');
                    $('.js-wrapStore .store-schedule-content  .saturday-time').html(`${marker.data.saturday}`);
                } else {
                    $('.js-wrapStore .store-schedule-content  .saturday-title').addClass('d-none');
                    $('.js-wrapStore .store-schedule-content  .saturday-time').html('');
                }

                if (marker.data.mondayFriday != '' && marker.data.mondayFriday != null) {
                    $('.js-wrapStore .store-schedule-content .mondayFriday-title').removeClass('d-none');
                    $('.js-wrapStore .store-schedule-content .mondayFriday-time').html(`${marker.data.mondayFriday}`);
                } else {
                    $('.js-wrapStore .store-schedule-content .mondayFriday-title').addClass('d-none');
                    $('.js-wrapStore .store-schedule-content .mondayFriday-time').html('');
                }

                marker.setIcon(icons.famaliving.icon);

            });

            //init store en map

            if (store.id === parseInt(this.utils.getCookie('geoCity'))) {
                new google.maps.event.trigger(marker, 'click');
            }


        });


        var myStyleCluster = {
            styles: [{
                textColor: '#fff',
                width: 40,
                height: 40,
                url: '/images/fl-cluster.svg',
                anchorText: [0, 0],
            }],
        };

        this.markerCluster = new MarkerClusterer (map, markers, myStyleCluster);

    }

    getStores() {
        if (!sessionStorage.getItem("isSetStores")) {
            console.log('se cargan stores');
            var options = {
                lang: $('html').attr('lang'),
            }

            $.ajax({
                url: "/api/get-stores",
                type: "post",
                data: options,
                dataType: "json",
                beforeSend: function () {

                },
                success: function (r) {

                    if (r.status === 'ok') {
                        r.stores.sort((a, b) => {
                            if (a.name < b.name) {
                                return -1;
                            }
                        });

                        sessionStorage.setItem("listStores", JSON.stringify(r.stores));
                        sessionStorage.setItem("isSetStores", true);
                    }
                },

            });

        } else {
            console.log('Hay sessionStorage stores');
        }
    }

    initUbication() {
        if (!"geolocation" in navigator) {
            this.positionIsSet = false;
            this.fromButton = true;
            this.latUser = null;
            this.longUser = null;
            return this.utils.toast.log("Tu navegador no soporta el acceso a la ubicación. Inténtalo con otro");
        }

        const onUbicacionConcedida = ubicacion => {
            this.positionIsSet = true;
            this.fromButton = false;
            this.latUser = ubicacion.coords.latitude;
            this.longUser = ubicacion.coords.longitude;
        }

        const onErrorDeUbicacion = err => {
            this.positionIsSet = false;
            this.fromButton = true;
            this.latUser = null;
            this.longUser = null;
        }

        const opcionesDeSolicitud = {
            enableHighAccuracy: false, // Alta precisión
            maximumAge: 0, // No queremos caché
            timeout: 25000 // Esperar 25 segundos
        };


        // Solicitar
        navigator.geolocation.getCurrentPosition(onUbicacionConcedida, onErrorDeUbicacion, opcionesDeSolicitud);
    }

    calcStoresDist(latUser, longUser, latStore, longStore) {
        var lat1 = latUser;
        var lon1 = longUser;

        var lat2 = latStore;
        var lon2 = longStore;

        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;

        const theta = lon1 - lon2;
        const radtheta = (Math.PI * theta) / 180;

        let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

        if (dist > 1) {
            dist = 1;
        }

        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344; //convert miles to km

        return dist;
    }

    setCookie(cname, cvalue, exdays) {
        this.utils.setCookie(cname, cvalue, exdays);
        this.getNameCity(cvalue);
    }

    getNameCity(cvalue) {
        var options = {
            id: cvalue,
            lang: $('html').attr('lang'),
        }
        $.ajax({
            url: "/api/get-store-by-cookie-id",
            data: options,
            type: "post",
            async: options.async,
            dataType: "json",
            beforeSend: function () {
            },
            success: function (r) {
                if (r.status == 'ok') {
                    let name;
                    $('.store-user').addClass('isSelectedStore');
                    if (r.store.store_name && r.store.store_name != '') {
                        name = (r.store.store_name).trim();
                    }
                    if ((r.store.store_name).trim().includes('Famaliving')) {
                        let storeNearArr = [];
                        storeNearArr = name.split(' ');
                        var storeNearArrLast = storeNearArr.splice(1, storeNearArr.length - 1);
                        var strName = storeNearArrLast.join(' ');
                        $('.store-user .store-user-name').html(strName.trim());
                    } else {
                        $('.store-user .store-user-name').html(name);
                    }

                    if ($('.js-nameCountryXs').length > 0) {
                        $('.js-nameCountryXs').html(r.store.country_name);
                        $('.js-nameCountryXs').closest('.title.small-line').addClass('isVisible');
                    }
                    if ($('.js-countryItem').length > 0) {
                        $('.js-countryItem[data-countryid=' + r.store.country_id + ']').trigger('click');
                        $('.js-nameCountryXs').closest('.title.small-line').addClass('isVisible');
                    }
                }
            }
        });
        var name = "";
        return name;
    }

    checkCookie(cname) {
        let result = this.utils.checkCookie(cname);

        if (result)
            this.getNameCity(parseInt(this.utils.getCookie(cname)));

        return result;
    }

    setStore(id) {
        var $storeId = id;
        this.utils.setCookie('geoCity', $storeId, 100);
        this.getNameCity($storeId);
    }

    changeStoreInfoMap(storeId) {
        var $storeId = parseInt(storeId);

        var markers = this.markers;

        //center on marker in the map
        markers.forEach(marker => {
            if ($storeId === marker.id) {
                google.maps.event.addListener(marker, 'click', this.clickMarker(marker));
            }
        })
    }

    clickMarker(marker) {
        //click on marker in the map
        var coordenadas = new google.maps.LatLng(marker.point_latitude, marker.point_longitude);
        this._map.setCenter(coordenadas);
        this._map.setZoom(15);

        var po_box = marker.po_box ? marker.po_box : "";

        var storeContent = `
        <div>`
        if (marker.address && marker.address != '' && marker.address != null) {
            storeContent = storeContent + `<span>${marker.address}, </span>`
        }
        if (marker.po_box && marker.po_box != '' && marker.po_box != null) {
            storeContent = storeContent + `<span>${marker.po_box}, </span>`
        }
        if (marker.postal_code && marker.postal_code != '' && marker.postal_code != null) {
            storeContent = storeContent + `<span>${marker.postal_code}, </span>`
        }
        if (marker.city && marker.city != '' && marker.city != null) {
            storeContent = storeContent + `<span>${marker.city}</span>`
        }
        `
        </div>
        `

        if ((marker.region && marker.region != '' && marker.region != null) && (marker.country && marker.country != '' && marker.country != null)) {
            storeContent = storeContent + `<div>${marker.region}<span>(${marker.country})</span></div>`
        }
        if ((marker.region && marker.region != '' && marker.region != null) && (!marker.country || marker.country == '' || marker.country == null)) {
            storeContent = storeContent + `<div>${marker.region}</div>`
        }
        if ((!marker.region || marker.region == '' || marker.region == null) && (marker.country && marker.country != '' && marker.country != null)) {
            storeContent = storeContent + `<div>${marker.country}</div>`
        }

        $('.js-wrapStore .store-info').html(storeContent);

        if (marker.name != null && marker.name != '') {
            let name;
            name = (marker.name).trim();

            if (name.trim().includes('Famaliving')) {
                let storeNearArr = [];
                storeNearArr = name.split(' ');

                var storeNearArrLast = storeNearArr.splice(1, storeNearArr.length - 1);
                var strName = storeNearArrLast.join(' ');

                $('.js-wrapStore .store-name .name').html(strName);
            } else {
                $('.js-wrapStore .store-name .name').html(name);
            }

        } else {
            $('.js-wrapStore .store-name .name').html('');
        }

        if (marker.phone != '' && marker.phone != null) {
            $('.js-wrapStore .store-buttons .tlf').removeClass('d-none');
            $('.js-wrapStore .store-buttons .tlf').attr('href', 'tel:' + `${marker.phone}`);
        } else {
            $('.js-wrapStore .store-buttons .tlf').addClass('d-none');
            $('.js-wrapStore .store-buttons .tlf').attr('href', '');
        }

        if (marker.phone_2 != '' && marker.phone_2 != null && marker.has_whatsapp) {
            $('.js-wrapStore .store-buttons .wapp').removeClass('d-none');
            $('.js-wrapStore .store-buttons .wapp').attr('href', 'https://api.whatsapp.com/send?phone=' + `${marker.phone_2}` + '&text=Hola');
        } else {
            $('.js-wrapStore .store-buttons .wapp').addClass('d-none');
            $('.js-wrapStore .store-buttons .wapp').attr('href', '');
        }

        if (marker.email != '' && marker.email != null) {
            $('.js-wrapStore .store-buttons .email').removeClass('d-none');
            $('.js-wrapStore .store-buttons .email').attr('href', 'mailto:' + `${marker.email}`);
        } else {
            $('.js-wrapStore .store-buttons .email').addClass('d-none');
            $('.js-wrapStore .store-buttons .email').attr('href', '');
        }

        let webStore;
        if (marker.slug && marker.slug != '' && marker.slug != null) {
            webStore = marker.slug;
            if (webStore.toLowerCase().indexOf('http') === -1) {
                webStore = "https://" + webStore;
            }
            $('.js-wrapStore .wrap-buttons .web').attr('href', `${webStore}`);
        }

        if (marker.saturday != '' && marker.saturday != null || marker.mondayFriday != '' && marker.mondayFriday != null) {
            let titleScheudule = $('.js-wrapStore .map-container-info').data('schedule');
            $('.js-wrapStore .store-schedule .title').html(`${titleScheudule}`);
        } else {
            $('.js-wrapStore .store-schedule .title').html('');
        }

        if (marker.saturday != '' && marker.saturday != null) {
            $('.js-wrapStore .store-schedule-content  .saturday-title').removeClass('d-none');
            $('.js-wrapStore .store-schedule-content  .saturday-time').html(`${marker.saturday}`);
        } else {
            $('.js-wrapStore .store-schedule-content  .saturday-title').addClass('d-none');
            $('.js-wrapStore .store-schedule-content  .saturday-time').html('');
        }

        if (marker.mondayFriday != '' && marker.mondayFriday != null) {
            $('.js-wrapStore .store-schedule-content  .mondayFriday-title').removeClass('d-none');
            $('.js-wrapStore .store-schedule-content .mondayFriday-time').html(`${marker.mondayFriday}`);
        } else {
            $('.js-wrapStore .store-schedule-content  .mondayFriday-title').addClass('d-none');
            $('.js-wrapStore .store-schedule-content  .mondayFriday-time').html('');
        }
    }

    filterStores(arrayStores) {
        $('.wrap-store .part-result .resNoNear').html('');
        var $visibleShops = [];
        $visibleShops.length = 0;
        var $arrStoresNear = [];
        $arrStoresNear.length = 0;
        var $visibleShopsNear = [];
        $visibleShopsNear.length = 0;
        var dist;
        var $storesNear = $('.stores-near');
        let container = $('.js-wrapStore');
        container.find('.stores-near').html('');
        $('.step-search .no-result').addClass('d-none');
        var name = "";

        container.find('.part-result').removeClass('d-none');

        if (this.positionIsSet && !this.fromButton) {
            var dist0 = this.calcStoresDist(this.latUser, this.longUser, arrayStores[0].point_latitude, arrayStores[0].point_longitude);

            arrayStores.forEach(storeNear => {
                dist = this.calcStoresDist(this.latUser, this.longUser, storeNear.point_latitude, storeNear.point_longitude);

                if (dist <= 160) {
                    let store = {'dist': dist, 'store': storeNear};
                    $visibleShops.push(store);
                } else {
                    if (dist <= dist0) {
                        dist0 = dist;
                        $visibleShopsNear[0] = storeNear;
                    }
                }

            });

            if ($visibleShops.length > 0) {
                $visibleShops = orderBy($visibleShops, ['dist', 'store'], ['asc', 'asc']);
                $('.wrap-store .part-result .resNoNear').html('');
            } else {
                let storeMostNear = {'dist': dist0, 'store': $visibleShopsNear[0]}
                $visibleShops.push(storeMostNear);
                var text = $('.wrap-store .part-result .resNoNear').data('info');
                text = text + ' ' + Number(dist0.toFixed(2)) + 'km';
                $('.wrap-store .part-result .resNoNear').html(text);
            }

            $('.wrap-store .wrap-buttons').removeClass('d-none');
            $('.wrap-store .wrap-buttons .web').addClass('d-none');
            $('.wrap-store .step-search').addClass('d-none');

            $visibleShops.forEach((storeNear) => {
                let $store = $('.js-patternStore').find('.store-near').clone();
                $store.attr('data-id', storeNear.store.id);

                if (storeNear.store.name && storeNear.store.name != '') {
                    name = (storeNear.store.name).trim().toUpperCase();
                }

                if (name.trim().toUpperCase().includes('FAMALIVING')) {
                    let storeNearArr = [];
                    storeNearArr = name.split(' ');

                    var storeNearArrLast = storeNearArr.splice(1, storeNearArr.length - 1);
                    var strName = storeNearArrLast.join(' ');
                    $store.find('.store-name').html(storeNearArr[0]);
                    $store.find('.color').html(strName);
                } else {
                    $store.find('.store-near-name .store-name').html(name);
                }

                if (storeNear.store.address && storeNear.store.address != '') {
                    $store.find('.store-address').html(storeNear.store.address);

                    if (storeNear.store.postal_code && storeNear.store.postal_code != '') {
                        $store.find('.store-address').append(', ' + storeNear.store.postal_code);
                    }

                    if (storeNear.store.city && storeNear.store.city != '') {
                        $store.find('.store-address').append(', ' + storeNear.store.city);
                    }
                    if (storeNear.store.region && storeNear.store.region != '') {
                        $store.find('.store-address').append(' (' + storeNear.store.region + ')');
                    }

                    /*
                    if(storeNear.store.country && storeNear.store.country != ''){
                        $store.find('.store-address').append(', ' + storeNear.store.country);
                    }
                    */

                    let text = $store.find('.store-address').data('dist');
                    $store.find('.store-address').append('<p class="storeDist" style="color:darkgray; margin-top: 1rem;">' + text + ' ' + Number(storeNear.dist.toFixed(2)) + 'km </p>');

                }

                if (storeNear.store.images && storeNear.store.images.length > 0) {
                    $store.find('.wrap-img .img').attr('src', storeNear.store.images[0].path);
                    $store.addClass('js-isPhoto');
                }
                $storesNear.append($store);
            });

        } else {
            if (this.fromButton) {
                $('.wrap-store .wrap-buttons').addClass('d-none');
            }
            $visibleShops = arrayStores;

            $visibleShops.forEach(storeNear => {

                let $store = $('.js-patternStore').find('.store-near').clone();
                $store.attr('data-id', storeNear.id);

                if (storeNear.name && storeNear.name != '') {
                    name = (storeNear.name).trim().toUpperCase();
                }

                if (name.trim().toUpperCase().includes('FAMALIVING')) {
                    let storeNearArr = [];
                    storeNearArr = name.split(' ');

                    var storeNearArrLast = storeNearArr.splice(1, storeNearArr.length - 1);
                    var strName = storeNearArrLast.join(' ');
                    $store.find('.store-name').html(storeNearArr[0]);
                    $store.find('.color').html(strName);
                } else {
                    $store.find('.store-near-name .store-name').html(name);
                }

                if (storeNear.address && storeNear.address != '') {
                    $store.find('.store-address').html(storeNear.address);
                }

                if (storeNear.postal_code && storeNear.postal_code != '') {
                    $store.find('.store-address').append(', ' + storeNear.postal_code);
                }

                if (storeNear.city && storeNear.city != '') {
                    $store.find('.store-address').append(', ' + storeNear.city);
                }

                if (storeNear.region && storeNear.region != '') {
                    $store.find('.store-address').append(' (' + storeNear.region + ')');
                }

                /*
                if(storeNear.country && storeNear.country != ''){
                    $store.find('.store-address').append(', ' + storeNear.country);
                }
                */

                if (storeNear.images && storeNear.images.length > 0) {
                    $store.find('.wrap-img .img').attr('src', storeNear.images[0].path);
                    $store.addClass('js-isPhoto');
                }
                $storesNear.append($store);
            });
        }


        $('.js-isPhoto').on('mouseenter', function () {
            $(this).find('.wrap-img').addClass('isVisible');
        });
        $('.js-isPhoto').on('mouseleave', function () {
            $(this).find('.wrap-img.isVisible').removeClass('isVisible');
        });

        $('.js-setStore').on('click', (e) => {
            var storeId = $(e.currentTarget).data('id');
            this.setStore(storeId);

            setTimeout(() => {
                $('input[name="searchCityAll"]').val('');
                container.find('input[name="searchCityAll"]').data('id', '');
                this.changeStoreInfoMap(storeId);
                this.seeMap();
            }, 200)
        });

    }

    ajaxGoogle(valueinput, isNum) {
        let isNumber = isNum;
        let value = valueinput;
        let $url = "";
        let codePais = this.countryUser;
        let keyGoogle = $('html').data('google');
        let baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json'

        if (isNumber) {
            $url = baseUrl + '?components=postal_code:' + value + '&region=' + codePais + '&sensor=false&types=(regions)&key=' + keyGoogle;
            //https://maps.googleapis.com/maps/api/geocode/json?postal_code:30300&components=country:ESP&sensor=false&types=(regions)&key=AIzaSyAsfUzV41D4lvpx-bn40CNT9ECifngMPPE
        } else {
            $url = baseUrl + '?address=' + value + '&region=' + codePais + '&sensor=false&types=(regions)&key=' + keyGoogle;
            //https://maps.googleapis.com/maps/api/geocode/json?address:cartagena&components=country:ES&sensor=false&types=(regions)&key=AIzaSyAsfUzV41D4lvpx-bn40CNT9ECifngMPPE
        }

        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
            $.ajax({
                url: $url,
                type: "post",
                dataType: "json",
                beforeSend: function () {
                }
            })
            .done((r) => {
                if (r.status === 'OK') {
                    this.fromButton = false;
                    $('.wrap-store .part-result .resNoNear').html('').removeClass('err');

                    if (r.results[0].geometry.location.lat && r.results[0].geometry.location.lng) {
                        this.resultGoogleStores(r.results[0].geometry.location.lat, r.results[0].geometry.location.lng);
                        this.isGoogleRes = true;
                    }
                } else {
                    this.isGoogleRes = false;
                    var text = $('.wrap-store .part-result .resNoNear').data('err');
                    $('.wrap-store .part-result .resNoNear').html(text).addClass('err');
                }
            })
            .fail((e) => {
                console.log(e)
                this.utils.toast.error(e.responseJSON || e.statusText || 'Ha ocurrido un error')
            })
            .always(() => {
                console.log('finally')
            })
        }, 2000);

    }

    resultGoogleStores(searchLat, searchLong) {
        $('.wrap-store .part-result .resNoNear').html('');

        let primeContainer = this.$primeContainer;
        this.fromButton = false;

        primeContainer.find('.store-near').addClass('isNotVisible');

        var lat1 = searchLat;
        var long1 = searchLong;
        var arrStoresSelect = [];
            arrStoresSelect.length = 0;
        var $visibleShops = [];
            $visibleShops.length = 0;
        var $arrStoresNear = [];
            $arrStoresNear.length = 0;
        var $visibleShopsNear = [];
            $visibleShopsNear.length = 0;
        var dist;
        var $storesNear = $('.stores-near');
        let container = $('.js-wrapStore');
        container.find('.stores-near').html('');
        $('.step-search .no-result').addClass('d-none');
        var name = "";
        var storesNearTrue = false;

        var arrayStores = this.markers;
        container.find('.part-result').removeClass('d-none');

        if (!this.fromButton) {
            var dist0 = this.calcStoresDist(lat1, long1, arrayStores[0].point_latitude, arrayStores[0].point_longitude);
            arrayStores.forEach((storeNear) => {
                dist = this.calcStoresDist(lat1, long1, storeNear.point_latitude, storeNear.point_longitude);

                if (dist <= 160) {
                    let store = {'dist': dist, 'store': storeNear};
                    $visibleShops.push(store);
                    storesNearTrue = true;
                } else {
                    if (dist <= dist0) {
                        dist0 = dist;
                        $visibleShopsNear[0] = storeNear;
                    }
                }
            });

            if ($visibleShops.length > 0) {
                $visibleShops = orderBy($visibleShops, ['dist', 'store'], ['asc', 'asc']);
                $('.wrap-store .part-result .resNoNear').html('');

                container.find('.step1').addClass('d-none');
                container.find('.step2').removeClass('d-none');

                container.find('.span1').removeClass('d-none');
                container.find('.span1-1').addClass('d-none');
                container.find('.name1-1').addClass('d-none');
            }
            else {
                let storeMostNear = {'dist': dist0, 'store': $visibleShopsNear[0]}
                $visibleShops.push(storeMostNear);
                var text = $('.wrap-store .part-result .resNoNear').data('info');
                text = text + ' ' + Number(dist0.toFixed(2)) + 'km';
                $('.wrap-store .part-result .resNoNear').html(text);

                container.find('.step1').addClass('d-none');
                container.find('.step2').removeClass('d-none');

                container.find('.span1').removeClass('d-none');
                container.find('.span1-1').addClass('d-none');
                container.find('.name1-1').addClass('d-none');

            }

            $('.wrap-store .wrap-buttons').removeClass('d-none');
            $('.wrap-store .wrap-buttons .web').addClass('d-none');
            $('.wrap-store .step-search').addClass('d-none');

        }
        else {
            if (this.fromButton) {
                $('.wrap-store .wrap-buttons').addClass('d-none');
            }
            $visibleShops = arrayStores;
        }

        $visibleShops.forEach((storeNear) => {

            let $store = $('.js-patternStore').find('.store-near').clone();
            $store.attr('data-id', storeNear.store.id);

            if (storeNear.store.name && storeNear.store.name != '') {
                name = (storeNear.store.name).trim().toUpperCase();
            }

            if (name.trim().toUpperCase().includes('FAMALIVING')) {
                let storeNearArr = [];
                storeNearArr = name.split(' ');
                var storeNearArrLast = storeNearArr.splice(1, storeNearArr.length - 1);
                var strName = storeNearArrLast.join(' ');
                $store.find('.store-name').html(storeNearArr[0]);
                $store.find('.color').html(strName);
            } else {
                $store.find('.store-near-name .store-name').html(name);
            }

            if (storeNear.store.address && storeNear.store.address != '') {
                $store.find('.store-address').html(storeNear.store.address);

                if (storeNear.store.postal_code && storeNear.store.postal_code != '') {
                    $store.find('.store-address').append(', ' + storeNear.store.postal_code);
                }

                if (storeNear.store.city && storeNear.store.city != '') {
                    $store.find('.store-address').append(', ' + storeNear.store.city);
                }

                if (storeNear.store.region && storeNear.store.region != '') {
                    $store.find('.store-address').append(' (' + storeNear.store.region + ')');
                }
                /*
                 if(storeNear.store.country && storeNear.store.country != ''){
                     $store.find('.store-address').append(', ' + storeNear.store.country);
                 }
                 */

                if (storesNearTrue) {
                    let text = $store.find('.store-address').data('dist');
                    $store.find('.store-address').append('<p class="storeDist" style="color:darkgray; margin-top: 1rem;">' + text + ' ' + Number(storeNear.dist.toFixed(2)) + 'km </p>');
                }
            }

            if (storeNear.store.images && storeNear.store.images.length > 0) {
                $store.find('.wrap-img .img').attr('src', storeNear.store.images[0].path);
                $store.addClass('js-isPhoto');
            }
            $storesNear.append($store);
        });


        $('.js-isPhoto').on('mouseenter', (e) => {
            $(e.currentTarget).find('.wrap-img').addClass('isVisible');
        });
        $('.js-isPhoto').on('mouseleave', (e) => {
            $(e.currentTarget).find('.wrap-img.isVisible').removeClass('isVisible');
        });

        $('.js-setStore').on('click', (e) => {
            var storeId = $(e.currentTarget).data('id');
            this.setStore(storeId);

            setTimeout(() => {
                $('input[name="searchCityAll"]').val('');
                container.find('input[name="searchCityAll"]').data('id', '');
                this.changeStoreInfoMap(storeId);
                this.seeMap();
            }, 200)
        });
    }

    seeMap() {
        let container = this.$primeContainer;
        container.removeClass('stepSelectOtherShop');
        container.removeClass('stepSelectStoresNear');
        container.removeClass('stepSeeListStore');

        container.find('.span1').removeClass('d-none');
        container.find('.title.small-line .name').removeClass('d-none');
        container.find('.span1-1').addClass('d-none');
        container.find('.name1-1').addClass('d-none');

        container.find('.wrap-buttons .web').removeClass('d-none');
        let textBtn = container.find('.wrap-buttons .btnShops').data('other');
        container.find('.wrap-buttons .btnShops .btnDefault').html(textBtn);
        container.find('.wrap-buttons').removeClass('d-none');
        container.find('.step-map').removeClass('d-none');
        container.find('.step1').removeClass('d-none');
        container.find('.step2').addClass('d-none');
        container.find('.part-result').addClass('d-none');
        container.find('.part-search-all').addClass('d-none');
        container.find('.wrap-btnDefault.web').removeClass('d-none');
        container.find('.js-stepSelectOtherShop').show();
        container.find('.js-stepSelectOtherShop').css('display', 'flex');
        container.find('.js-seeListStore').hide();
        container.find('.js-back').addClass('d-none');
        container.find('.js-back').removeClass('back1');
        container.find('.js-back').removeClass('back2');
        container.find('.js-back').removeClass('back3');
    }

    stepSelectStoresNear() {
        let container = this.$primeContainer;
        container.removeClass('stepSelectOtherShop');
        container.addClass('stepSelectStoresNear');
        container.removeClass('stepSeeListStore');

        container.find('.step-search').addClass('d-none');

        container.find('.js-back').addClass('back1');
        container.find('.js-back').removeClass('back2');
        container.find('.js-back').removeClass('back3');

        container.find('.js-back').off('click').on('click', () => {
            this.seeMap();
        });
    }

    seeStoreNearBack() {
        let container = this.$primeContainer;
        container.addClass('stepSelectOtherShop');
        container.removeClass('stepSelectStoresNear');
        container.removeClass('stepSeeListStore');

        container.find('.step-search').removeClass('d-none');
        container.find('.part-search').removeClass('d-none');
        container.find('.part-result').addClass('d-none');

        container.find('.js-back').removeClass('back1');
        container.find('.js-back').addClass('back2');
        container.find('.js-back').removeClass('back3');


        container.find('.js-back').off('click').on('click', () => {
            this.seeMap();
        });
    }

    keyUpCityAll(e) {
        e.preventDefault();

        var inputsearch;
        let isNumber = false;
        let primeContainer = this.$primeContainer;

        inputsearch = $(e.currentTarget).val();

        if (inputsearch.length > 0) {

            primeContainer.find('.wrap-buttons').addClass('d-none');
            primeContainer.find('.wrap-button .web').removeClass('d-none');
            this.hayValor = true;

            if (!isNaN(inputsearch)) {
                isNumber = true;

            } else {
                isNumber = false;
            }

            inputsearch = this.utils.removeAccents(inputsearch.trim().toLowerCase().toString());
            this.ajaxGoogle(inputsearch, isNumber);

        } else {
            this.hayValor = false;
            primeContainer.find('.store-near').each(function () {
                $(this).removeClass('isNotVisible');
            });
            primeContainer.find('.wrap-buttons').addClass('d-none');
            primeContainer.find('.wrap-buttons .web').removeClass('d-none');
            let textBtn = primeContainer.find('.wrap-buttons .btnShops').data('other');
            primeContainer.find('.wrap-buttons .btnShops .btnDefault').html(textBtn);
            primeContainer.find('.wrap-buttons .js-stepSelectOtherShop').addClass('d-none');
        }

    }

    //show list of result
    seeListStore(e) {
        let $elem = $(e.currentTarget);
       //console.log('eeee', e.currentTarget);
        var container = this.$primeContainer;
        container.find('.resNoNear').html('');

        if ($elem.hasClass('js-selectedStore') || $elem.hasClass('js-stepSelectOtherShop')) {
            this.fromButton = true;
            container.find('.step-search').removeClass('d-none');

            if ($elem.hasClass('js-stepSelectOtherShop')) {
                container.find('.step1').removeClass('d-none');
                container.find('.step2').addClass('d-none');
                container.find('.span1').addClass('d-none');
                container.find('.span1-1').removeClass('d-none');
                container.find('.name1-1').removeClass('d-none');
            } else {
                container.find('.span1').removeClass('d-none');
                container.find('.span1-1').addClass('d-none');
                container.find('.name1-1').addClass('d-none');
            }

        } else {
            this.fromButton = false;
            container.find('.span1').removeClass('d-none');
            container.find('.span1-1').addClass('d-none');
            container.find('.name1-1').addClass('d-none');
        }
        container.removeClass('stepSelectOtherShop');
        container.removeClass('stepSelectStoresNear');
        container.addClass('stepSeeListStore');

        container.find('input[name="searchCityAll"]').val('');
        container.find('input[name="searchCityAll"]').data('id', '');

        container.find('.wrap-buttons').addClass('d-none');
        container.find('.step-map').addClass('d-none');

        container.find('.title.small-line .name').addClass('d-none');
        container.find('.step-search').removeClass('d-none');

        this.filterStores(this.markers);
        container.find('.part-search-all').removeClass('d-none');

        if (this.utils.checkCookie('geoCity')) {
            container.find('.js-back').removeClass('d-none');
        }

        container.find('.js-back').removeClass('back1');
        container.find('.js-back').removeClass('back2');

        if (this.utils.checkCookie('geoCity')) {
            container.find('.js-back').addClass('back3');
        }

        container.find('.js-back').off('click').on('click', () => {

            if (!this.utils.checkCookie('geoCity')) {
                this.fromButton = false;
                container.find('.js-back').addClass('d-none');
                this.filterStores(this.markers);
            } else {
                this.fromButton = true;
                this.seeMap();
            }

        });

    }

    openSelectStore(e) {
        var primeContainer = this.$primeContainer;
        if ($(e.currentTarget).hasClass('js-openSelectStore')) {
            this.fromButton = false;

            if (!this.positionIsSet && !this.utils.checkCookie('geoCity')) {
                primeContainer.find('.part-search-all').removeClass('d-none');
            } else {
                primeContainer.find('.part-search-all').addClass('d-none');
            }

        }
        /**close search if is open */
        if ($('.topbar .btn-close').length > 0 && $('.topbar .btn-close').hasClass('is-open')) {
            $(".topbar  .js-finder-close").trigger('click');
            $('.topbar .full-level-black').removeClass('isVisible');
        }

        //create sessionStorage before y asign value to mapMenu.common._markers
        /**
         if(sessionStorage.getItem("isSetStores")){
            mapMenu.common._markers=JSON.parse(sessionStorage.getItem("listStores"));
            if(mapMenu.common._markers.length>0){

                var loader=primeContainer.find('.comment-loader');
                loader.show();
                $('.content-wrap-stores').hide();

                mapMenu.common.init();
                mapMenu.common.filterStores(mapMenu.common._markers);
                mapMenu.common.seeMapInit();

                $('.content-wrap-stores').show();
                loader.hide();

                if(!mapMenu.common.checkCookie('geoCity')) {
                    mapMenu.common.filterStores(mapMenu.common._markers);
                }
            }else{
                mapMenu.common.seeMapInit();

                if(!mapMenu.common.checkCookie('geoCity')) {
                    mapMenu.common.filterStores(mapMenu.common._markers);
                }
            }
        }
         */


        if (this.markers.length <= 0) {
            var loader = primeContainer.find('.comment-loader');
            loader.show();
            $('.content-wrap-stores').hide();
            var options = {
                lang: $('html').attr('lang'),
            }

            $.ajax({
                url: "/api/get-stores",
                type: "post",
                data: options,
                dataType: "json",
                beforeSend: function () {

                },
                success: (r) => {

                    if (r.status === 'ok') {
                        r.stores.sort((a, b) => {
                            if (a.name < b.name) {
                                return -1;
                            }
                        });
                        this.markers = r.stores;
                        this.init();
                        this.filterStores(this.markers);
                        this.seeMapInit();

                        $('.content-wrap-stores').show();
                        loader.hide();
                    }
                },
                finally: () => {
                    $('.content-wrap-stores').show();
                    loader.hide();
                }
            });
        } else {
            this.seeMapInit();
            if (!this.utils.checkCookie('geoCity')) {
                this.filterStores(this.markers);
            }

        }

        // define a function that sets min-height of my-element to window.innerHeight:
        $('.js-wrapStore.wrap-store').addClass('is-open');
        $('.js-wrapStore.wrap-store').css('height', window.innerHeight + "px");
        $('.full-level-black').addClass('isVisibleStore');
        $('body').addClass('stop-scroll-store');
    }

    seeMapInit() {
        var primeContainer = this.$primeContainer;
        if (!this.utils.checkCookie('geoCity')) {

            if (this.fromButton) {
                primeContainer.find('.step-map').addClass('d-none');
                primeContainer.find('.step-search').removeClass('d-none');
                primeContainer.find('.part-result').removeClass('d-none');
                primeContainer.find('.step1').addClass('d-none');
                primeContainer.find('.step2').removeClass('d-none');
                primeContainer.find('.wrap-buttons').addClass('d-none');
            } else {
                primeContainer.find('.step-map').addClass('d-none');
                primeContainer.find('.step-search').removeClass('d-none');
                primeContainer.find('.part-result').removeClass('d-none');
                primeContainer.find('.step1').addClass('d-none');
                primeContainer.find('.step2').removeClass('d-none');

                if (!this.positionIsSet) {
                    primeContainer.find('.step1').removeClass('d-none');
                    primeContainer.find('.step2').addClass('d-none');
                    primeContainer.find('.step1 .span1').addClass('d-none');
                    primeContainer.find('.step1 .name').addClass('d-none');
                    primeContainer.find('.step1 .span1-1').removeClass('d-none');
                    primeContainer.find('.step1 .name1-1').removeClass('d-none');
                    primeContainer.find('.wrap-buttons').addClass('d-none');
                }
            }

        } else {
            primeContainer.find('.step-map').removeClass('d-none');
            primeContainer.find('.part-result').addClass('d-none');
            primeContainer.find('.step-search').addClass('d-none');
            primeContainer.find('.step1').removeClass('d-none');
            primeContainer.find('.step2').addClass('d-none');
            primeContainer.find('.wrap-buttons').removeClass('d-none');
            primeContainer.find('.wrap-buttons .web').removeClass('d-none');
        }
    }

    closeAllMenuVertical() {
        let deviceWidth = window.matchMedia("(max-width: 991px)");
        if (!deviceWidth.matches) {
            // set an event listener that detects when innerHeight changes:
            window.addEventListener("resize", () => {
                if (!deviceWidth.matches) {
                    this.closeSelectStore();
                    $('.topbar').removeClass('is-open');
                    $('.topbar').find('.full-level-black').removeClass('isVisible');
                    $('html, body').removeClass('stop-scroll');
                }
            });

            this.closeSelectStore();
            $('.topbar').removeClass('is-open');
            $('.topbar').find('.full-level-black').removeClass('isVisible');
            $('html, body').removeClass('stop-scroll');
        }
    }

    closeSelectStore() {
        var container = this.$primeContainer;
        //is exist geoCity or no

        container.find('.js-back').addClass('d-none');
        $('.js-wrapStore.wrap-store.is-open').removeClass('is-open');
        $('.full-level-black').removeClass('isVisibleStore');
        $('body').removeClass('stop-scroll-store');
        container.removeClass('stepSelectOtherShop');
        container.removeClass('stepSelectStoresNear');
        container.removeClass('stepSeeListStore');
        container.find('.step-search').addClass('d-none');

        container.find('.span1').removeClass('d-none');
        container.find('.title.small-line .name').removeClass('d-none');
        container.find('.span1-1').addClass('d-none');
        container.find('.name1-1').addClass('d-none');

        container.find('input[name="searchCity"]').val('');
        container.find('input[name="searchCity"]').data('id', '');
        container.find('input[name="searchCityAll"]').val('');
        container.find('input[name="searchCityAll"]').data('id', '');

        container.find('.part-search-all').addClass('d-none');

        if (this.utils.checkCookie('geoCity')) {
            container.find('.wrap-buttons .web').removeClass('d-none');
            let textBtn = container.find('.wrap-buttons .btnShops').data('other');
            container.find('.wrap-buttons .btnShops .btnDefault').html(textBtn);
            container.find('.js-stepSelectOtherShop').show();
            container.find('.js-stepSelectOtherShop').css('display', 'flex');
            container.find('.js-seeListStore').hide();
            container.find('.step2').addClass('d-none');
            container.find('.step1').removeClass('d-none');
            container.find('.step-map').removeClass('d-none');
            container.find('.wrap-buttons').removeClass('d-none');

        } else {
            container.find('.part-result').addClass('d-none');
            container.find('.step1').addClass('d-none');
            container.find('.step2').removeClass('d-none');
        }

    }

    openBack(e) {
        let elem = $(e.currentTarget);
        elem.addClass('hover');
    }

    closeBack(e) {
        let elem = $(e.currentTarget);
        elem.removeClass('hover');
    }


}






