//USE FAMALIVING STORES
import $ from 'jquery'

export class StoresFamaliving {

    static instance
    static getInstance() {
        if (!StoresFamaliving.instance)
        {
            StoresFamaliving.instance = new StoresFamaliving();
        }
        return StoresFamaliving.instance;
    }

    constructor() {
        this.eventListeners()
    }

    init() {}

    eventListeners() {
        $('.js-changeImageStores').on('mouseover', (e) => {this.changeImage(e)});
        $('.js-changeImageStores').on('mouseout', (e) => {this.rollbackImage(e)});
        $('.js-collapseStoresMobile').on('click', (e) => {this.toggleStores(e)});
    }

    changeImage(e) {
        let $elem=$(e.currentTarget);
        //console.log('storesFamaliving changeImage');
        let image = $elem.data('image');
        $('.wrap-shops-image img').attr('src', image);
    }

    rollbackImage(e) {
        //console.log('storesFamaliving rollbackImage');
        let default_image = $('#default_image').val();
        if (default_image)
            $('.wrap-shops-image img').attr('src', default_image);
    }

    toggleStores(e) {
        //console.log('storesFamaliving toggleStores', e);
        let $elem=$(e.currentTarget);
        let dataId = $elem.data('key');
        let $shopItem = $('.shop-item[data-key="'+ dataId +'"]');
        if ($shopItem.hasClass('collapse')) {
            $shopItem.removeClass('collapse');
        } else {
            $shopItem.addClass('collapse');
        }
    }
}
