import {FamaUtils} from "../../classes/FamaUtils";

export class Topbar {
    utils = FamaUtils.getInstance();

    constructor() {
        fama.utils.debug('TopBar loaded')
        this.eventListeners();
        this.init();
    }


    eventListeners() {
        $('.topbar .js-open-menu').off('click').on('click', (e) => {this.openMenu(e)});
        //$(".topbar  .js-finder-close").on('click', () => {this.closeFinder();});
        //$(".topbar  .js-finder-open").on('click', (e) => {this.toggleFinder(e)});
        $('.topbar  .js-open-submenu').on('click', (e) => {this.openSubmenu(e)});
    }

    init() {
        var prevScrollpos = window.scrollY;

        $("#topbar").removeClass('topOut');

        $(window).on('scroll',() => {
            var inMobile = parseFloat($('body').css('width')) <= 991;
            var currentScrollPos = window.scrollY;

            if($('#topbar').length > 0 && !$('#topbar').hasClass('is-open')){

                // scrolling up
                if (prevScrollpos >= currentScrollPos || window.scrollY < 102) {
                    $("#topbar").css('top','0');
                    $("#topbar").removeClass('topOut');

                    if($('#wrap-banner').length > 0){
                        $(".wrap-banner").css('top','0');

                        if(inMobile){
                            $(".wrap-banner").css('padding','0 1.5rem');
                            $(".wrap-banner").removeClass('isMobile');
                        }else{
                            $(".wrap-banner").css('padding','0 5rem');
                        }
                    }

                }

                // scrolling down
                else{
                    $("#topbar").addClass('topOut');
                    if($('body.stop-scroll').length < 1){
                        $("#topbar").css('top','-10.2rem');

                        if($('#wrap-banner').length > 0){
                            $(".wrap-banner").css('top','-11.2rem');
                            $(".wrap-banner").css('padding','0');
                        }

                    }

                    if(inMobile){
                        $("#topbar").css('top','-7rem');
                        if($('#wrap-banner').length > 0){
                            $(".wrap-banner").css('top','-7.5rem');
                            $(".wrap-banner").addClass('isMobile');
                            $(".wrap-banner").css('padding','0');
                        }
                    }
                }
                prevScrollpos = currentScrollPos;
            }
        })
    }

    openSubmenu(e) {
        e.preventDefault();
        let $submenu = $(e.currentTarget).closest('.item').toggleClass('is-open').find('ul')
        if($submenu.is(':visible')){
            $submenu.stop().slideUp();
        }
        else{
            $submenu.stop().slideDown();
        }
    }

    toggleFinder(e) {
        if($('.topbar').hasClass('is-open')){
            $('.topbar').toggleClass('is-open');
            $('.topbar').find('.full-level-black').removeClass('isVisible');
        }

        if(window.innerWidth<992 || document.documentElement.clientWidth<992 || document.body.clientWidth<992 || document.body.offsetWidth<992){

            $('.topbar .full-level-black').addClass('isVisible');
        }

        if($('.wrap-store.is-open').length>0){
            $('.js-storeClose').trigger('click');
        }

        $('body, html').addClass('stop-scroll');
        $('.btn-search').hide();

        $('.topbar .btn-close').show();
        $('.topbar .btn-close').addClass('is-open');
    }

    openMenu(e) {
        /**close search until open menu */
        if($('.topbar .btn-close').length>0 && $('.topbar .btn-close').hasClass('is-open')){
            $(".topbar  .js-finder-close").trigger('click');

            $('.topbar .full-level-black').toggleClass('isVisible');
            if(this.utils.isMobile() && $('.topbar .full-level-black').hasClass('isVisible')){
                $('.topbar .full-level-black').addClass('isVisible');
            }
        }

        $('.topbar').toggleClass('is-open');
        $('.topbar').find('.full-level-black').toggleClass('isVisible');

        ($('.topbar').hasClass('is-open'))
            ? $('html, body').addClass('stop-scroll')
            : $('html, body').removeClass('stop-scroll');
    }

    closeFinder() {
        $('.modal-finder').fadeOut().find('.results').hide();
        $('body, html').removeClass('stop-scroll');
        $('.topbar .btn-close').hide();
        $('.btn-search').show();
        $('.js-finder').val('');
        $('.topbar .full-level-black').removeClass('isVisible');
    }
}
