import $ from "jquery";
import {FamaUtils} from "../../classes/FamaUtils";

export class Sliders {
    utils = FamaUtils.getInstance();
    isFirst = true;

    /** Sliders instance */
    static _instance

    /**
     * Singleton method
     * @returns instance
     */
    static getInstance() {
        if (!Sliders._instance) {
            Sliders._instance = new Sliders()
        }

        return Sliders._instance
    }

    defaultConfig = [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            }
        }
    ];

    constructor() {
        this.init()
        this.eventListeners();
    }


    eventListeners() {
        $('.js-openFull').on('click', (e) => {this.seeFullImage(e)});
        $('.js-closeFull').on('click', (e) => {this.closeFullImage(e)});

        // Listeners User Slider
        $('.js-userMore').on('click', (e) => {this.seeMoreUser(e)});

        // Listen resize
        $(window).on('resize',() => {
            var inMobile = this.utils.inMobileBody();
            if($('.our-services .slick-slider.slick-initialized').length > 0){
                if(inMobile && this.isFirst){
                    $('.our-services .slick-slider.slick-initialized')[0].slick.refresh();
                    this.isFirst = false;
                }
            }
        });
    }

    init() {
        console.log('SliderDetail loaded');

        if($('.slider-slick-detail-store:not(.slick-initialized)').length > 0) {
            this.initSliderDetail();
        }

        if($('.slider-home').length > 0) {
            this.initSliderHome();
        }

        if($('.slider-category').length > 0){
            this.initSliderCategory();
        }

        if($('.js-slider-wrap:not(.slider-store)').length>0) {
            this.initSliderGeneral();
        }

        if($('.js-slider-wrap-no-arrows').length>0) {
            this.initSliderGenericArrows()
        }

        if($('.js-slider-post').length>0) {
            this.initSliderPost();
        }

        if($('.slider-type-simulation').length>0) {
            this.initSliderSimulation();
        }

        if($('.slider-shop').length > 0) {
            this.initSliderShop();
        }

        if($('.wrap-users .carousel-container').length>0) {
            this.initSliderUsers();
        }

        if($('.our-services .carousel-container').length>0) {
            this.initSliderOurServices();
        }
    }

    initSliderDetail() {
            $('.slider-slick-detail-store:not(.slick-initialized)').each((i, item) =>{

                console.log('Load slider detail store');
                $(item).slick({
                    rows: 0,
                    centerMode: true,
                    slidesToShow: 1,
                    infinite: true,
                    dots: false,
                    speed: 500,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    focusOnSelect: true,
                    arrows: true,
                    autoplay: true,
                    lazyLoad: 'ondemand',
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                swipeToSlide: true,
                                arrows: false,
                                centerMode: true,
                                slidesToShow: 1,
                                variableWidth: false,
                            }
                        },

                    ]
                })
            })


            $('.new-store .slider-slick-detail-store.slick-slider').on('afterChange', function (){
                console.log('afterChange2');
                let slider= $(this).closest('.js-slider-wrap');

                let numActive= slider.find('.slick-slide.slick-current.js-changeSlider').data('num-index');

                slider.find('.scrollbar-item.isActive').removeClass('isActive');
                slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

            });

            $('.new-store .slick-slider:not(.slider-users) .slick-slide:not(.slick-cloned)').each((key, elem) => {

                $(elem).on('click', (e) => {
                    console.log('.new-store .slick-slider click2');
                    let slide=$(e.currentTarget);
                    let index=slide.data('slick-index');
                    let id=slide.closest('.part-slider.slider').data('id');
                    this.seeGallery(slide, index, id);
                })
            })

    }

    seeGallery(slide, index, id) {
        let slideClick=slide;
        let slideIndex=index;
        let sliderId=id;
        var gallery=slideClick.closest('.part-slider.slider').find('.mygallerybox');

        sliderId='#' + sliderId;
        var mygallerybox = new Swiper($('.new-store .mygallerybox[data-slider="'+ sliderId +'"] .swiper-id'), {
            centeredSlides: true,
            slidesPerView: 1,
            slideToClickedSlide: true,
            preloadImages: false,
            lazyLoading: true,
            lazyLoadingInPrevNext: true,
            keyboardControl: true,
            shortSwipes: false,
            longSwipesRatio: 0.1,
            longSwipesMs: 100,
            mousewheelControl: true,
            prevButton: $('.new-store .mygallerybox[data-slider="'+ sliderId +'"] .swiper-id').find(".swiper-button-prev:not(.fin)"),
            nextButton: $('.new-store .mygallerybox[data-slider="'+ sliderId +'"] .swiper-id').find(".swiper-button-next:not(.fin)"),
            spaceBetween: 0
        });
        if(slideIndex===0){
            mygallerybox.slideTo(0,0);
        }else{
            mygallerybox.slideTo(slideIndex,400);
        }

        gallery.addClass("preopen");
        gallery.addClass("open").removeClass("preopen");
    }

    initSliderHome() {
        $('.slider-home').slick({
            centerMode: true,
            slidesToShow: 1,
            infinite: true,
            dots: false,
            speed: 3000,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            arrows: false,
            autoplay: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 975,
                    settings: {
                        swipeToSlide: true,
                        arrows: false,
                        useTransform: true,
                        autoplaySpeed: 2000,
                        easing: 'easeOutElastic',
                        cssEase: 'cubic-bezier(0.61, 1, 0.88, 1)'
                    }
                },

            ]
        });

        $('.slider-home').on('afterChange', (e) =>{
            let slider= $(e.currentTarget).closest('.js-slider-wrap');
            let numActive= slider.find('.slick-slide.slick-current .item-slide').data('num-index');
            slider.find('.scrollbar-item.isActive').removeClass('isActive');
            slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');
        });
    }

    initSliderCategory() {
        $('.slider-category').slick({
            rows: 0,
            centerMode: true,
            slidesToShow: 1,
            infinite: true,
            dots: false,
            speed: 1000,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            arrows: false,
            autoplay: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 975,
                    settings: {
                        swipeToSlide: true,
                    }
                },

            ]
        })
    }

    initSliderGeneral() {
        let sliderToShow = $('.js-slider-wrap:not(.slider-store)').data('slider-to-show');
        let sliderToShowTbl = $('.js-slider-wrap:not(.slider-store)').data('slider-to-show-tbl');
        let sliderToShowTblLg = $('.js-slider-wrap:not(.slider-store)').data('slider-to-show-tbl-lg');
        let sliderToShowMvl = $('.js-slider-wrap:not(.slider-store)').data('slider-to-show-mvl');

        sliderToShow = (sliderToShow) ? sliderToShow : 2;
        sliderToShowTblLg = (sliderToShowTblLg) ? sliderToShowTblLg : 2;
        sliderToShowTbl = (sliderToShowTbl) ? sliderToShowTbl : 2;
        sliderToShowMvl = (sliderToShowMvl) ? sliderToShowMvl : 1;

        $('.js-slider').slick({
            rows: 0,
            centerMode: false,
            slidesToShow: sliderToShow,
            infinite: false,
            initialSlide: 0,
            dots: false,
            speed: 500,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            variableWidth: false,
            arrows: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: sliderToShowTblLg,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: sliderToShowTbl,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: sliderToShowMvl,
                    }
                },

            ]
        })

        $('.js-slider:not(.slider-slick-detail-store)').on('afterChange', (e) =>{
            let slider= $(e.currentTarget).closest('.js-slider-wrap');
            let numActive= slider.find('.slick-slide.slick-current .blog-entry').data('num-index');
            slider.find('.scrollbar-item.isActive').removeClass('isActive');
            slider.find('.scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

        });
    }

    initSliderGenericArrows() {
        let sliderToShow = $('.js-slider-wrap-no-arrows').data('slider-to-show');
        sliderToShow = (sliderToShow) ? sliderToShow : 2;

        $('.js-slider-no-arrows').slick({
            rows: 0,
            centerMode: false,
            slidesToShow: sliderToShow,
            infinite: false,
            initialSlide: 0,
            dots: false,
            speed: 500,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            variableWidth: true,
            arrows: false,
            lazyLoad: 'ondemand',
            responsive: this.defaultConfig
        })

        $('.js-slider-no-arrows').on('afterChange', (e) =>{
            let slider= $(e.currentTarget).closest('.js-slider-wrap-no-arrows');
            let numActive= slider.find('.slick-slide.slick-current .blog-entry').data('num-index');

            slider.find('.scrollbar-item.isActive').removeClass('isActive');
            slider.find('.scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

        });
    }

    initSliderPost() {
        $('.js-slider-post').slick({
            rows: 0,
            centerMode: true,
            slidesToShow: 1,
            infinite: false,
            initialSlide: 0,
            dots: false,
            speed: 500,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            variableWidth: false,
            arrows: false,
            lazyLoad: 'ondemand',
            responsive: this.defaultConfig
        })

        $('.js-slider-post').on('afterChange', (e) => {
            let slider= $(e.currentTarget).closest('.js-slider-wrap');
            let numActive= slider.find('.slick-slide.slick-current .blog-entry').data('num-index');
            slider.find('.scrollbar-item.isActive').removeClass('isActive');
            slider.find('.scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');
        });
    }

    initSliderSimulation() {
        const options = {
            centerMode: true,
            slidesToShow: 1,
            infinite: true,
            dots: true,
            speed: 1000,
            slidesToScroll: 1,

            swipeToSlide: false,
            draggable: false,
            touchMove: false,
            swipe: false,

            focusOnSelect: true,
            arrows: false,
            autoplay: true,
            //variableWidth: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        swipeToSlide: true,
                        arrows: false,
                        centerMode: true,
                        slidesToShow: 1,

                        draggable: true,
                        touchMove: true,
                        swipe: true,
                    }
                },

            ]
        };

        // my slick slider as const object
        $('.slider-type-simulation').not('.slick-initialized').on('init', (slick) => {

            // set this slider as const for use in set time out
            const slider = slick;

            // slight delay so init completes render
            setTimeout(() => {
                // dot buttons
                let dots = $('.slick-dots > li > button', slider);

                let num=0;
                //each dot button function
                $.each(dots, (i,e) => {
                    // slide id
                    let slide_id = $(e).attr('aria-controls');
                    // custom dot image
                    let dot_img = $('#'+slide_id).find('.js-changeSlider').data('dot-img');
                    $(this).attr('data-dot-num', num);
                    num++;
                    $(this).html('<img src="' + dot_img + '" alt="" />');

                });


            }, 100);

        }).slick(options);
    }

    initSliderShop() {
        $('.slider-shop').slick({
            rows: 0,
            centerMode: false,
            slidesToShow: 1,
            infinite: false,
            dots: false,
            speed: 500,
            slidesToScroll: 1,
            focusOnSelect: true,
            arrows: true,
            autoplay: false,
            draggable: false,
            touchMove: false,
            swipeToSlide: false,
            swipe: false,
            variableWidth: true,
        })

        var $slider=$('.slider-shop');
        var $progressBar = $('.map-container-info .progress');
        var $progressBarLabel = $( '.map-container-info .slider__label' );

        $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;

            $progressBar
                .css('background-size', calc + '% 100%')
                .attr('aria-valuenow', calc );

            $progressBarLabel.text( calc + '% completed' );
        });
    }

    initSliderUsers() {
        $('.wrap-users .carousel-container').slick({
            rows: 0,
            centerMode: false,
            slidesToShow: 2,
            infinite: false,
            initialSlide: 0,
            dots: false,
            speed: 500,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            variableWidth: false,
            arrows: true,
            adaptiveHeight: true,
            lazyLoad: 'ondemand',
            responsive: [

                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                },

            ]
        })
    }

    seeMoreUser(e){
        let $elem = $(e.currentTarget)
        $elem.closest('.item-user').find('.wrap-description').toggleClass('is-open');
        $elem.toggleClass('is-open');
        let height = $elem.closest('.wrap-users').find('.wrap-img').innerHeight();

        let top=parseInt(height / 2);
        let oldTop=`calc(50% - 2rem)`;
        let newTop=`${top}px`;

        let elem1=$('.wrap-users .slick-prev');
        let elem2=$('.wrap-users .slick-next');

        elem1.css({top: newTop});
        elem2.css({top: newTop});

        if($('.wrap-description.is-open').length<1){
            elem1.css({top: oldTop});
            elem2.css({top: oldTop});
        }
    }

    initSliderOurServices() {
        $('.our-services .carousel-container').slick({
            rows: 0,
            centerMode: false,
            slidesToShow: 5,
            infinite: false,
            initialSlide: 0,
            dots: false,
            speed: 500,
            slidesToScroll: 5,
            swipeToSlide: true,
            focusOnSelect: true,
            variableWidth: false,
            arrows: true,
            draggable: false,
            adaptiveHeight: true,
            lazyLoad: 'ondemand',

            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,

                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        arrows: false,
                        adaptiveHeight: false,
                        draggable: true,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                        adaptiveHeight: false,
                    }
                },

            ]
        })
    }

    seeFullImage(e){
        var imgFullBackground;
        var srcCurrentImg=$(e.currentTarget).find('img').attr('src');
        imgFullBackground=$(e.currentTarget).closest('.container-shops').find('.wrap-full-background');
        imgFullBackground.find('.imgActive').attr('src', srcCurrentImg);
        imgFullBackground.addClass('isOpen');

    }

    closeFullImage(e){
        var imgFullBackground=$(e.currentTarget).closest('.wrap-full-background');
        imgFullBackground.removeClass('isOpen');
    }
}
