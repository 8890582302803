//use detail of store (famaliving) Detail of store Famaliving 2023
import $ from 'jquery'

'use strict'
import axios from 'axios'

export class Store {
    constructor() {
        this.init();
    }

    init() {
        if($('.famaliving-detail-generic-store').length > 0){
            $(window).scroll(() => {
                $('.famaliving-detail-generic-store .wrap-content >.article-famaliving').each((i, item) => {
                    let index=$(item).data('index');
                    if ($(window).scrollTop() >= $(item).position().top - 300) {
                        if (!$(`.inner-famaliving[data-index="${index}"]`).hasClass('isSelected')) {
                            if($('.inner-famaliving.isSelected').data('index')!=index){
                                $('.inner-famaliving.isSelected').removeClass('isSelected');
                                $(`.inner-famaliving[data-index="${index}"]`).addClass('isSelected');
                            }
                        }
                    }
                });
            });
        }

        this.eventListeners();
    }

    eventListeners() {
        $('.js-openSchedule').on('click', (e) => {this.openSchedule(e)});
        $('.js-appointment').on('click', () => {this.openAppointment()});
        $('.js-closeAppointment').on('click', () => {this.closeAppointment()});
        $('.js-famalivingScrollSection').on('click', (e) => {this.scrollSection(e)});
    }

    scrollSection(e) {
        let elem=$(e.currentTarget);
        let sectionID=elem.attr('data-index');
        let elemScroll=$(`.article-famaliving[data-index="${sectionID}"]`);

        $('html, body').animate({
            scrollTop: elemScroll.offset().top - 102
        }, 400, 'swing');
    }

    openSchedule(e){
        let elem=$(e.currentTarget);
        elem.closest('.section').toggleClass('is-open');
    }
    openAppointment(){
        $('.wrap-calendly').addClass('isOpen');
        $('body, html').addClass('stop-scroll');
    }
    closeAppointment(){
        $('.wrap-calendly').removeClass('isOpen');
        $('body, html').removeClass('stop-scroll');
    }

}
