//use garantia (famaliving)
import $ from 'jquery'
import {FamaUtils} from "../../classes/FamaUtils";

export class Warranty {
    utils = FamaUtils.getInstance();

    constructor() {
        this.init();
    }

    init() {
        $("#garantias_famaliving2023").each((key, item) => {
            var id = $(item);
            id.find('.js-select2').select2({
                width: 'resolve',
            });

            $("#garantias_famaliving2023 button[type='submit']").unbind().click((e) => {
                e.preventDefault();
                var form_ok = true;
                var $botton=$(e.currentTarget);

                // validation of select2
                if (!$.isNumeric($("#garantias_famaliving2023 select[id='pais_warranty']").val()) || $("#garantias_famaliving2023 select[id='pais_warranty']").val() <= 0) {
                    e.preventDefault();
                    $("#garantias_famaliving2023 select[id='pais_warranty']").siblings('.select2').find(".select2-selection").css("border-color", "red");
                    form_ok = false;
                }else{
                    $("#garantias_famaliving2023 select[id='pais_warranty']").siblings('.select2').find(".select2-selection").css("border-color", "");
                }

                if (!$("#garantias_famaliving2023 input[type='checkbox']").is(":checked")) {
                    $("#garantias_famaliving2023 input[type='checkbox']").parent().addClass('error');
                    form_ok = false;
                } else {
                    $("#garantias_famaliving2023 input[type='checkbox']").parent().removeClass('error');
                }

                $("#garantias_famaliving2023 input[type='text']").each((key, item) => {
                    if ($(item).val() == "") {
                        $(item).css("border-color", "red");
                        form_ok = false;
                    } else {
                        $(item).css("border-color", "");
                    }
                });


                if (form_ok) {
                    $("#garantias_famaliving2023 form button[type='submit']").prop('disabled', true);
                    $("#garantias_famaliving2023 form button[type='submit']").closest('.wrap-btnDefault').addClass('loading');

                    var options = {
                        codigo: $("#garantias_famaliving2023 input[name='codigo']").val(),
                        nombre: $("#garantias_famaliving2023 input[name='nombre']").val(),
                        apellidos: $("#garantias_famaliving2023 input[name='apellidos']").val(),
                        email: $("#garantias_famaliving2023 input[name='email']").val(),
                        phone: $("#garantias_famaliving2023 input[name='phone']").val(),
                        localidad: '1',
                        direccion: '1',
                        codigopostal: $("#garantias_famaliving2023 input[name='codigopostal']").val(),
                        pais: $("#garantias_famaliving2023 select[name='pais']").val(),
                        lang: $("#garantias_famaliving2023 input[name='lang']").val(),
                        valoracion: $("#garantias_famaliving2023 input[name='rate']:checked").val(),
                        valoracion_texto: $("#garantias_famaliving2023 textarea[name='rate_comment']").val(),
                        valoracion_producto: $("#garantias_famaliving2023 input[name='rate_product']").val(),
                    }

                    $.ajax({
                        url: "/ajax.php?module=garantias&action=nueva&language=" + options.lang,
                        data: options,
                        type: "post",
                        async: true,
                        dataType: "json",
                        beforeSend: () => {
                            $("#garantias_famaliving2023 form button[type='submit']").prop('disabled', true);
                        },
                        success: (r) => {
                            $botton.prop('disabled', false);
                            $("#garantias_famaliving2023 form button[type='submit']").closest('.wrap-btnDefault').removeClass('loading');
                            this.utils.toast.log(r.message);

                            $("#garantias_famaliving2023 input[name='codigo']").val("");
                            $("#garantias_famaliving2023 input[name='nombre']").val("");
                            $("#garantias_famaliving2023 input[name='apellidos']").val("");
                            $("#garantias_famaliving2023 input[name='email']").val("");
                            $("#garantias_famaliving2023 input[name='phone']").val("");

                            $("#garantias_famaliving2023 input[name='codigopostal']").val("");
                            $("#garantias_famaliving2023 input[name='rate']").prop('checked', false);
                            $("#garantias_famaliving2023 textarea[name='rate_comment']").val('');
                            $("#garantias_famaliving2023 input[name='rate_product']").val('');
                            $("#garantias_famaliving2023 input[name='acept']").prop('checked', false);

                            $("#garantias_famaliving2023 form button[type='submit']").prop('disabled', false);
                            $("#garantias_famaliving2023 input[type='checkbox']").prop('checked', false);

                            $('#garantias_famaliving2023 select[name="pais"]').find('option[value="0"]').attr('selected','selected');
                            $('#garantias_famaliving2023 select[name="pais"]').prop('selectedIndex',0);

                            $('#garantias_famaliving2023 select[name="pais"]').select2('destroy');
                            $('#garantias_famaliving2023 select[name="pais"]').find("select option").eq(0).html();
                            $('#garantias_famaliving2023 select[name="pais"]').select2();

                        },

                    });
                }else{
                    $("#garantias_famaliving2023 form button[type='submit']").prop('disabled', false);
                    $("#garantias_famaliving2023 form button[type='submit']").closest('.wrap-btnDefault').removeClass('loading');
                }

            });

        });
    }
}
