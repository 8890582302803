//use blog pos (famaliving) Blog comment Famaliving 2023
import { FamaUtils } from "../../classes/FamaUtils";
import $ from 'jquery'

export class BlogComment {
    utils = window.fama.utils || FamaUtils.getInstance()

    constructor() {
        this.init();
    }

    init() {
        $("#comentario_blog_famaliving").each(() => {

            $("#comentario_blog_famaliving button[type='submit']").unbind().click((e) => {
                e.preventDefault();
                var form_ok = true;
                if (!$("#comentario_blog_famaliving input[type='checkbox']").is(":checked")) {
                    $("#comentario_blog_famaliving input[type='checkbox']").parent().addClass('error');
                    form_ok = false;
                } else {
                    $("#comentario_blog_famaliving input[type='checkbox']").parent().removeClass('error');
                }
                $("#comentario_blog_famaliving input[type='text']").each((key, item) => {
                    if ($(item).val() == "") {
                        $(item).css("border-color", "red");
                        form_ok = false;
                    } else {
                        $(item).css("border-color", "");
                    }
                });

                $("#comentario_blog_famaliving textarea[type='text']").each((key, item) => {
                    if ($(item).val() == "") {
                        $(item).css("border-color", "red");
                        form_ok = false;
                    } else {
                        $(item).css("border-color", "");
                    }
                });

                $("#comentario_blog_famaliving input[type='email']").each((key, item) => {
                    if ($(item).val() == "") {
                        $(item).css("border-color", "red");
                        form_ok = false;
                    } else {
                        if (!this.utils.isEmailValid($(item).val())) {
                            $(item).css("border-color", "red");
                            form_ok = false;
                        } else {
                            $(item).css("border-color", "");
                        }
                    }
                });
                if (form_ok) {
                    var form = $("#comentario_blog_famaliving").serialize();
                    this.send();
                }
            });
        });
    }

    send() {
        let options = {
            blogid: $("#comentario_blog_famaliving input[name='idblog']").val(),
            siteid: $("#comentario_blog_famaliving input[name='idsite']").val(),
            lang: $("#comentario_blog_famaliving input[name='lang']").val(),
            alias: $("#comentario_blog_famaliving input[name='alias']").val(),
            email: $("#comentario_blog_famaliving input[name='email']").val(),
            comentario: $("#comentario_blog_famaliving textarea[name='comentario']").val()
        }
        $.ajax({
            url: "/ajax.php?module=blog_famaliving&action=comment&language=" + options.lang,
            data: options,
            type: "post",
            async: true,
            dataType: "json",

            success: (r) => {

                let lastLomment=$('#comentario_blog_famaliving').closest('.comments').find('.coment-item:last');
                let numComment=parseInt($("#comentario_blog_famaliving").closest('.wrap-comments-content').find('.numComments').attr('data-num'));
                if (lastLomment.length > 0){
                    ;
                    $("#comentario_blog_famaliving").closest('.comments').find('.wrap-comments').html(r.commenthtml);
                    let comments=$("#comentario_blog_famaliving").closest('.wrap-comments-content').find('.numComments').attr('data-comments');

                    $("#comentario_blog_famaliving").closest('.wrap-comments-content').find('.title-comments').find('.title').html(comments);
                }else{

                    $("#comentario_blog_famaliving").closest('.comments').prepend(r.commenthtml);
                    let comment=$("#comentario_blog_famaliving").closest('.wrap-comments-content').find('.numComments').attr('data-comment');

                    $("#comentario_blog_famaliving").closest('.wrap-comments-content').find('.title-comments').find('.title').html(comment);

                }

                numComment++;

                this.utils.toast.log($("input[name='comentario_publicado']").val());
                $("#comentario_blog_famaliving").closest('.wrap-comments-content').find('.numComments').attr('data-num', numComment);
                $("#comentario_blog_famaliving").closest('.wrap-comments-content').find('.numComments').html(numComment);
                $("#comentario_blog_famaliving").closest('.comments').find('.no-comments').hide();
                $("#comentario_blog_famaliving").closest('.wrap-comments-content').find('.title-comments').addClass('isSee');

                $("#comentario_blog_famaliving")[0].reset();
            }
        });
    }
}
