//use store tour (famaliving)

import $ from 'jquery'

export class Select2 {
    constructor() {
        this.init();
        this.eventListeners();
    }

    init() {
        if( $("select[name='list-tours']").length > 0){
            $("select[name='list-tours']").select2();
        }
    }

    eventListeners() {
        $(document).on('select2:select', '#list-tours', e => this.changeTour(e));
    }

    changeTour(e) {
        let elemId= parseInt(e.params.data.id);

        let $option = $('option[data-id="'+ elemId +'"]');
        let $wrapper = $('.tour .tour-wrapper');
        let city=$option.data('name');

        if(city.trim().includes('Famaliving')){
            let arrName=[];
            arrName=city.split(' ');

            var arrNameList=arrName.splice(1, arrName.length-1);
            var strName=arrNameList.join(' ');

            $wrapper.find('.description span').html(strName);
        }else{
            $wrapper.find('.description span').html($option.data('name'));
        }
        //console.log('image', $option.data('image'));

        $wrapper.find('.name').html($option.data('name'));

        $wrapper.find('a').attr('href', $option.data('url'));
        ($option.data('image') !== undefined)
            ? $wrapper.find('img').attr('src', $option.data('image'))
            : $wrapper.find('img').attr('src', '/images/no-image.webp') ;

        //console.log('option', $option, $option.data('image'), $option.data('url'), $option.data('name'));
    }
}
